
export type TranslateFunction = (source: string) => string | null;

export class IntegrationMetadata {

    constructor(
        public readonly translateBreed: TranslateFunction,
        public readonly translateColor: TranslateFunction
    ) {
    }

    isValidBreed(sparkieBreed: string): boolean {
        let breed = this.translateBreed(sparkieBreed);
        return !!breed;
    }

    isValidColor(sparkieColor: string): boolean {
        let color = this.translateColor(sparkieColor);
        return !!color;
    }
}

export function buildMap(set: Set<string>) : Map<string, string> {
    let map = new Map<string, string>();

    for (let breed of set) {
        let key = buildKey(breed);
        map.set(key, breed);
    }

    return map;
}

export function buildKey(breed: string) : string {
    if (breed == null) {
        return "";
    } else {
        return breed.toLowerCase()
        .replace(/ dog/g, '')
        .replace(/ mix/g, '')
        .replace(/ /g, '');
    }
}
